import React, { useState, useEffect, useRef } from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import "./product-list.less";
import { navigate } from "gatsby";
// import listImg1 from "../images/product/list_img1.png"
// import listImg2 from "../images/product/list_img2.png"
// import listImg3 from "../images/product/list_img3.png"
import robotImg1 from "../images/home/robot_gre1.png";
import robotImg2 from "../images/home/robot_gre2.png";
import robotImg3 from "../images/home/robot_gre3.png";
import product1 from "../images/product/listImg1.png";
import product2 from "../images/product/listImg2.png";
import product3 from "../images/product/listImg3.png";
import product4 from "../images/product/listImg4.png";
import footImg from "../images/home/foot-logo.png";
import banner0 from "../images/product/banner0.png";
import Fade from "react-reveal/Fade";
export default function ProductList(props) {
  const [titleColor, setTitleColor] = useState(false);
  const productTitle = useRef(null);

  const listArr = [
    {
      id: 0,
      img: robotImg1,
      h3: "Break down data silos",
      p: "Our platform enables broad data collaboration to solve complex problems that require data silos to be broken down - all within a set standard of policy guidelines - that you define.",
    },
    {
      id: 1,
      img: robotImg2,
      h3: "Retain ownership and control",
      p: "Our platform lets you own, control, trade and drive value from your data. It enables your customers and stakeholders to assert their legal rights.",
    },
    {
      id: 2,
      img: robotImg3,
      h3: "Data access  management",
      p: "Define and enforce precision access rights on datasets via smart contracts and secure virtual SD-WAN networks",
    },
  ];
  const productList = [
    { id: 0, img: product1, title: "Securely collaborate" },
    { id: 1, img: product2, title: "Create value" },
    { id: 2, img: product3, title: "Comply" },
    { id: 3, img: product4, title: "Govern" },
  ];
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        setTitleColor(entries[0].isIntersecting);
      },
      { threshold: [1] }
    );
    observer.observe(productTitle.current);
  });

  return (
    <>
      <div className="productlist">
        <Header />
        <div className="top_text">
          <h3 ref={productTitle} className={titleColor ? "shadow" : ""}>
            product
          </h3>
          <p>Sightline Innovation security products</p>
        </div>
        <div className="securely_collaborate">
          <div className="securely_collaborate_text">
            <Fade distance={"20%"} top duration={1300} top>
              <h3>{props.data?.title || "Securely collaborate"}</h3>
            </Fade>
            <p>
              {props.data?.text ||
                "Our platform enables enterprise data collaboration which can be used to solve complex problems by breaking down data silos, with full control of data rights and access"}
            </p>
          </div>
          <div className="big_img">
            <img src={props.data?.img || banner0} alt="" />
          </div>
          <ul>
            {listArr.map((item, index) => {
              return (
                <li key={item.id}>
                  <div>
                    <img src={props.data?.icon[index] || item.img} alt="" />
                  </div>
                  <h3>{item.h3}</h3>
                  <p>{item.p}</p>
                </li>
              );
            })}
          </ul>
        </div>
        <div className="product_list">
          <Fade distance={"20%"} top duration={1300} top>
            <h3>Product List</h3>
          </Fade>
          <ul>
            {productList.map((item, index) => {
              return (
                <li
                  key={index}
                  onClick={() => navigate("/productList?id=" + index)}
                >
                  <div>
                    <img src={item.img} alt="" />
                  </div>
                  <h3>{item.title}</h3>
                </li>
              );
            })}
          </ul>
        </div>
        <div className="product_logo">
          <h3>The future stars today</h3>
          <div onClick={() => navigate("/contactUs")}>Get Started</div>
        </div>
      </div>

      <Footer />
    </>
  );
}
