import React from "react";
import ProductList from "../templates/product-list";
import banner0 from "../images/product/banner0.png";
import banner1 from "../images/product/banner1.png";
import banner2 from "../images/product/banner2.png";
import banner3 from "../images/product/banner3.png";

import listImg1 from "../images/product/list_img1.png";
import listImg2 from "../images/product/list_img2.png";
import listImg3 from "../images/product/list_img3.png";
import listImg4 from "../images/product/list_img4.png";
import listImg5 from "../images/product/list_img5.png";
import listImg6 from "../images/product/list_img6.png";
import listImg7 from "../images/product/list_img7.png";
import listImg8 from "../images/product/list_img8.png";
import listImg9 from "../images/product/list_img9.png";
import listImg10 from "../images/product/list_img10.png";
import listImg11 from "../images/product/list_img11.png";
import listImg12 from "../images/product/list_img12.png";

export default function productList() {
  let idx =
    typeof window !== "undefined"
      ? window.location.search.slice(window.location.search.length - 1)
      : "";

  const productData = [
    {
      id: 0,
      title: "Securely collaborate",
      icon: [listImg1, listImg2, listImg3],
      text: "Our platform enables enterprise data collaboration which can be used to solve complex problems by breaking down data silos, with full control of data rights and access",
      img: banner0,
    },
    {
      id: 1,
      title: "Create value",
      icon: [listImg4, listImg5, listImg6],
      text: "Our platform allows you to gain valuable insights from your data and create new revenue streams, under precise audit and control systems",
      img: banner1,
    },
    {
      id: 2,
      title: "Comply",
      icon: [listImg7, listImg8, listImg9],
      text: "Our platform provides distributed data rights management and facilitates compliance with global privacy legislation. Privacy controls can be adjusted based on data sensitivity.",
      img: banner2,
    },
    {
      id: 3,
      title: "Govern",
      icon: [listImg10, listImg11, listImg12],
      text: "Our platform establishes data control, rights, routing, and exchange policies, and provides a trusted and secure way to address data usage and derivative data.",
      img: banner3,
    },
  ];
  return <ProductList data={productData[idx]} />;
}
